/**
 * 自采登记状态
 */
export const SELF_PROCUREMENT_STATUS_ENUM = {
  10: {
    value: 10,
    label: "未复核",
  },
  20: {
    value: 20,
    label: "已复核",
  },
  30: {
    value: 30,
    label: "已扣款",
  },
};
