<template>
  <div class="self-procurement-register">
    <!-- 表单搜索 start -->
    <div class="form-wrap">
      <div class="seach">
        <div class="inputs">
          <el-input
            v-model="formData.product_name"
            placeholder="登记商品名称"
            clearable
          ></el-input>
        </div>
        <div class="inputs">
          <el-input
            v-model="formData.business_user_name"
            placeholder="登记员姓名"
            clearable
          ></el-input>
        </div>
        <div class="inputs">
          <el-input
            v-model="formData.receiver_name"
            placeholder="收货人姓名"
            clearable
          ></el-input>
        </div>
        <div class="inputs">
          <el-input
            v-model="formData.receiver_mobile"
            placeholder="收货人电话"
            clearable
          ></el-input>
        </div>
        <div class="inputs">
          <!-- :filter-method="(val) => onFilterMethod('cityStore', val)"
          @visible-change="(val) => onChangeVisible('cityStore', val)" -->
          <el-select
            filterable
            v-model="formData.logistics_id"
            clearable
            placeholder="选择城市仓"
            @change="onChangeCityStore"
          >
            <el-option
              v-for="item in cityStoreList"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </div>
        <div class="inputs">
          <el-select
            filterable
            v-model="formData.delivery_id"
            clearable
            :filter-method="(val) => onFilterMethod('selfTake', val)"
            @visible-change="(val) => onChangeVisible('selfTake', val)"
            placeholder="选择自提点"
          >
            <el-option
              v-for="item in selfTakeList"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </div>
        <div class="inputs">
          <el-input
            v-model="formData.shipper_name"
            placeholder="发货人姓名"
            clearable
          ></el-input>
        </div>
        <div class="inputs">
          <el-input
            v-model="formData.shipper_mobile"
            placeholder="发货人电话"
            clearable
          ></el-input>
        </div>
        <div class="inputs">
          <el-input
            v-model="formData.self_supply_no"
            placeholder="自采品编码"
            clearable
          ></el-input>
        </div>
        <div class="inputs">
          <el-select v-model="formData.qc_status" clearable placeholder="状态">
            <el-option label="全部" value="0"></el-option>
            <el-option label="未复核" value="10"></el-option>
            <el-option label="已复核" value="20"></el-option>
            <el-option label="已扣款" value="30"></el-option>
          </el-select>
        </div>
        <div class="inputs">
          <el-date-picker
            v-model="pay_at"
            :time-arrow-control="true"
            type="daterange"
            format="yyyy-MM-dd"
            value-format="yyyy-MM-dd"
            range-separator="—"
            start-placeholder="登记时间始"
            end-placeholder="登记时间止"
          >
          </el-date-picker>
        </div>
        <div class="inputs">
          <el-select v-model="formData.source" clearable placeholder="请选择来源">
            <el-option label="线上" value="2"></el-option>
            <el-option label="线下" value="1"></el-option>
         
          </el-select>
        </div>
        <div class="inputs">
          <el-select v-model="formData.status" clearable placeholder="请选择登记状态">
            <el-option label="已失效" value="1"></el-option>
            <el-option label="正常" value="0"></el-option>
         
          </el-select>
        </div>
        <div class="inputs">
          <el-button
            type="primary"
            icon="el-icon-refresh"
            class="refresh"
            @click="onHandleRefresh"
          ></el-button>
          <!-- 增加提现状态 end -->
          <el-button @click="onSearch" type="primary" icon="el-icon-search"
            >查询</el-button
          >
          <el-button
            type="warning"
            @click="adddialog"
            icon="el-icon-circle-plus-outline"
            >添加</el-button
          >
          <el-button
            type="warning"
            @click="exportHandle"
            icon="el-icon-download"
            >导 出</el-button
          >
        </div>
      </div>
    </div>
    <!-- 表单搜索 end -->

    <!-- 列表 start -->
    <div class="table-wrap">
      <el-table
        :data="tableData"
        height="100%"
        v-loading="loading"
        :border="true"
        :header-cell-style="{ color: '#333333', background: '#EFF6FF' }"
      >
        <el-table-column type="index" width="100px" align="center" label="序号">
        </el-table-column>
        <el-table-column prop="business_name" align="center" label="集配中心">
        </el-table-column>
        <el-table-column
          prop="self_supply_no"
          align="center"
          label="自采品编码"
        >
        </el-table-column>
        <el-table-column prop="logistics_name" align="center" label="城市仓">
        </el-table-column>
        <el-table-column prop="delivery_name" align="center" label="自提点">
        </el-table-column>
        <el-table-column
          prop="business_user_name"
          align="center"
          label="登记员姓名"
        >
        </el-table-column>
        <el-table-column prop="receiver_name" align="center" label="收货人姓名">
        </el-table-column>
        <el-table-column
          prop="receiver_mobile"
          align="center"
          label="收货人电话"
        >
        </el-table-column>
        <el-table-column prop="shipper_name" align="center" label="发货人姓名">
        </el-table-column>
        <el-table-column
          prop="shipper_mobile"
          align="center"
          label="发货人电话"
        >
        </el-table-column>
        <el-table-column
          prop="product_name"
          align="center"
          label="登记商品名称"
        >
        </el-table-column>
        <el-table-column prop="create_time" align="center" label="登记时间">
        </el-table-column>
        <el-table-column prop="weight" align="center" label="登记重量（斤）">
        </el-table-column>
        <el-table-column
          prop="qc_weight"
          align="center"
          label="复核确认重量（斤）"
        >
        </el-table-column>
        <el-table-column prop="product_num" align="center" label="登记件数">
        </el-table-column>
        <el-table-column
          prop="qc_product_num"
          align="center"
          label="复核确认件数"
        >
        </el-table-column>
        <el-table-column
          prop="freight_amount"
          align="center"
          label="服务费金额"
        >
        </el-table-column>
        <el-table-column  align="center" label="打单人员">
          <template slot-scope="scope">
          {{scope.row.print_business_user?scope.row.print_business_user.nickname:''}}
         
          </template>
        </el-table-column>
        <el-table-column prop="qc_status" align="center" label="状态">
          <template slot-scope="scope">
            <span v-if="scope.row.qc_status == 10"> 未复核</span>
            <span v-else-if="scope.row.qc_status == 20">已复核</span>
            <span v-else-if="scope.row.qc_status == 30">已扣款</span>
          </template>
        </el-table-column>
        <el-table-column prop="qc_status" align="center" label="登记状态">
          <template slot-scope="scope">
            <span v-if="scope.row.status == 0"> 正常</span>
            <span v-else-if="scope.row.status == 1">已失效</span>
            <!-- <span v-else-if="scope.row.qc_status == 30">已扣款</span> -->
          </template>
        </el-table-column>
        <el-table-column label="操作" align="center">
          <template slot-scope="scope">
       <div v-if="scope.row.status==0">
        <div v-if="scope.row.source==2">
              <el-button
              type="text"
              @click="edit(scope.row)"
            v-if="scope.row.qc_status != 30"
              >编辑</el-button
            >
            </div>
          <div v-else>
            <el-button
              type="text"
              @click="edit(scope.row)"
              v-if="
                isShowEdit ||
                Number(scope.row.qc_status) <
                  SELF_PROCUREMENT_STATUS_ENUM['20'].value
              "
              >编辑</el-button
            >
          </div>
       </div>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <!-- 列表 end -->

    <!-- 分页 start -->
    <div class="fy">
      <el-pagination
        type="primary"
        background
        @size-change="onHandleSizeChange"
        @current-change="onHandleCurrentChange"
        :current-page="formData.page"
        :page-sizes="[10, 20, 50, 100]"
        :page-size="formData.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </div>

    <el-dialog
      :center="true"
      v-if="personVisible"
      @close="personVisible = false"
      :visible.sync="personVisible"
      :title="personTitle"
      :append-to-body="true"
    >
      <div style="width: 80%">
        <el-form
          :model="personForm"
          :rules="fromrules"
          ref="ruleForm"
          label-width="20%"
          class="demo-ruleForm from-wraps"
        >
          <el-form-item label="集配中心" prop="business_id">
            <el-input
              v-model="personForm.business_name"
              :disabled="true"
              placeholder="请输入集配中心"
            ></el-input>
          </el-form-item>
          <el-form-item label="选择城市仓" prop="logistics_id">
            <el-select
              filterable
              v-model="personForm.logistics_id"
              clearable
              @change="changelogistics"
              placeholder="选择城市仓"
            >
              <el-option
                v-for="item in cityStoreList"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="选择自提点" prop="delivery_id">
            <el-select
              filterable
              v-model="personForm.delivery_id"
              clearable
              @change="deliverychange"
              placeholder="选择自提点"
            >
              <el-option
                v-for="item in zitList"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <div v-if="personbtn == 'add'">
            <el-row v-for="(item, i) in personForm.product_arr" :key="i">
              <div class="position">
                <el-form-item
                  label="商品名称"
                  :prop="`product_arr.${i}.product_name`"
                  :rules="[
                    {
                      required: true,
                      message: '请输入商品名称',
                      trigger: 'blur',
                    },
                  ]"
                >
                  <el-autocomplete
                    style="width: 100%"
                    v-model="item.product_name"
                    :fetch-suggestions="
                      (queryString, cb) =>
                        querySearchAsync('1', queryString, cb)
                    "
                    placeholder="商品名称"
                  ></el-autocomplete>
                  <!-- <el-select
                    filterable
                    v-model="item.product_name"
                    remote
                    clearable
                    style="width: 100%"
                    reserve-keyword
                    :disabled="false"
                    :remote-method="(val) => remoteMethod('1', val, i)"
                    :loading="loadingselect"
                    placeholder="商品名称"
                  >
                    <el-option
                      v-for="item in product_name_list"
                      :key="item.value"
                      :label="item.value"
                      :value="item.value"
                    >
                    </el-option>
                  </el-select> -->

                  <!-- <el-input
                  v-model="item.product_name"
                  :disabled="false"
                  placeholder="请输入商品名称"
                ></el-input> -->
                </el-form-item>
                <el-form-item
                  label="件数"
                  :prop="`product_arr.${i}.product_num`"
                  :rules="rulesnum"
                >
                  <el-input
                    v-model="item.product_num"
                    :disabled="false"
                    clearable
                    @blur="numchange(i)"
                    placeholder="请输入件数"
                  ></el-input>
                </el-form-item>
                <el-form-item
                  label="每件斤数"
                  :prop="`product_arr.${i}.each_kilogram`"
                >
                  <el-input
                    v-model="item.each_kilogram"
                    :disabled="false"
                    clearable
                    @blur="numchange(i)"
                    placeholder="请输入每件斤数"
                  ></el-input>
                </el-form-item>
                <el-form-item
                  label="总斤数"
                  :prop="`product_arr.${i}.weight`"
                  :rules="[
                    {
                      required: true,
                      message: '请输入总斤数',
                      trigger: 'blur',
                    },
                  ]"
                >
                  <el-input
                    v-model="item.weight"
                    :disabled="false"
                    clearable
                    placeholder="请输入总斤数"
                  ></el-input>
                </el-form-item>
                <div class="position-subset">
                  <i
                    class="el-icon-circle-plus"
                    v-if="
                      personForm.product_arr.length == i + 1 &&
                      !personForm.showbtn
                    "
                    @click="addproduct"
                  ></i>
                  <i
                    class="el-icon-delete-solid"
                    v-if="
                      personForm.product_arr.length != 1 && !personForm.showbtn
                    "
                    @click="delproduct(i)"
                  ></i>
                </div>
              </div>
            </el-row>
          </div>
          <div v-else>
            <el-row>
              <div class="position">
                <el-form-item
                  label="商品名称"
                  prop="product_name"
                  :rules="[
                    {
                      required: true,
                      message: '请输入商品名称',
                      trigger: 'blur',
                    },
                  ]"
                >
                  <el-autocomplete
                    style="width: 100%"
                    v-model="personForm.product_name"
                    :fetch-suggestions="
                      (queryString, cb) =>
                        querySearchAsync('1', queryString, cb)
                    "
                    placeholder="商品名称"
                  ></el-autocomplete>
                  <!-- <el-select
                    filterable
                    v-model="personForm.product_name"
                    remote
                    clearable
                    style="width: 100%"
                    reserve-keyword
                    :disabled="true"
                    :remote-method="(val) => remoteMethod('1', val, i)"
                    :loading="loadingselect"
                    placeholder="商品名称"
                  >
                    <el-option
                      v-for="item in product_name_list"
                      :key="item.value"
                      :label="item.value"
                      :value="item.value"
                    >
                    </el-option>
                  </el-select> -->

                  <!-- <el-input
                  v-model="item.product_name"
                  :disabled="false"
                  placeholder="请输入商品名称"
                ></el-input> -->
                </el-form-item>
                <el-form-item
                  label="件数"
                  prop="product_num"
                  :rules="[
                    {
                      required: true,
                      message: '请输入件数',
                      trigger: 'blur',
                    },
                  ]"
                >
                  <el-input
                    v-model="personForm.product_num"
                    :disabled="false"
                    clearable
                    @blur="numchangeobj()"
                    placeholder="请输入件数"
                  ></el-input>
                </el-form-item>
                <el-form-item label="每件斤数" prop="each_kilogram">
                  <el-input
                    v-model="personForm.each_kilogram"
                    :disabled="false"
                    clearable
                    @blur="numchangeobj()"
                    placeholder="请输入每件斤数"
                  ></el-input>
                </el-form-item>
                <el-form-item
                  label="总斤数"
                  prop="weight"
                  :rules="[
                    {
                      required: true,
                      message: '请输入总斤数',
                      trigger: 'blur',
                    },
                  ]"
                >
                  <el-input
                    v-model="personForm.weight"
                    :disabled="false"
                    clearable
                    placeholder="请输入总斤数"
                  ></el-input>
                </el-form-item>
              </div>
            </el-row>
          </div>

          <el-form-item label="收货人姓名" prop="receiver_name">
            <el-autocomplete
              style="width: 100%"
              v-model="personForm.receiver_name"
              @blur="name_blur('2')"
              @select="name_blur('2')"
              :fetch-suggestions="
                (queryString, cb) => querySearchAsync('2', queryString, cb)
              "
              placeholder="收货人姓名"
            ></el-autocomplete>
            <!-- <el-select
              filterable
              v-model="personForm.receiver_name"
              remote
              clearable
              style="width: 100%"
              reserve-keyword
              :disabled="false"
              :remote-method="(val) => remoteMethod('2', val)"
              :loading="loadingselect"
              placeholder="收货人姓名"
            >
              <el-option
                v-for="item in receiver_name_list"
                :key="item.value"
                :label="item.value"
                :value="item.value"
              >
              </el-option>
            </el-select> -->
          </el-form-item>
          <el-form-item label="收货人电话" prop="receiver_mobile">
            <el-autocomplete
              style="width: 100%"
              v-model="personForm.receiver_mobile"
              :fetch-suggestions="
                (queryString, cb) => querySearchAsync('3', queryString, cb)
              "
              placeholder="收货人电话"
            ></el-autocomplete>
            <!-- <el-select
              filterable
              v-model="personForm.receiver_mobile"
              remote
              clearable
              style="width: 100%"
              reserve-keyword
              ref="sgSelect"
              :disabled="false"
              :remote-method="(val) => remoteMethod('3', val)"
              :loading="loadingselect"
              placeholder="收货人电话"
              @input.native="sgfilterData(1)"
            >
              <el-option
                v-for="item in receiver_mobile_list"
                :key="item.value"
                :label="item.value"
                :value="item.value"
              >
              </el-option>
            </el-select> -->
          </el-form-item>
          <el-form-item label="发货人姓名" prop="shipper_name">
            <el-autocomplete
              style="width: 100%"
              v-model="personForm.shipper_name"
              @blur="name_blur('4')"
              @select="name_blur('4')"
              :fetch-suggestions="
                (queryString, cb) => querySearchAsync('4', queryString, cb)
              "
              placeholder="发货人姓名"
            ></el-autocomplete>
            <!-- <el-select
              filterable
              v-model="personForm.shipper_name"
              remote
              clearable
              style="width: 100%"
              reserve-keyword
              :disabled="false"
              :remote-method="(val) => remoteMethod('4', val)"
              :loading="loadingselect"
              placeholder="发货人姓名"
            >
              <el-option
                v-for="item in shipper_name_list"
                :key="item.value"
                :label="item.value"
                :value="item.value"
              >
              </el-option>
            </el-select> -->
          </el-form-item>
          <el-form-item label="发货人电话" prop="shipper_mobile">
            <el-autocomplete
              style="width: 100%"
              v-model="personForm.shipper_mobile"
              :fetch-suggestions="
                (queryString, cb) => querySearchAsync('5', queryString, cb)
              "
              placeholder="发货人电话"
            ></el-autocomplete>
            <!-- <el-select
              filterable
              v-model="personForm.shipper_mobile"
              remote
              clearable
              style="width: 100%"
              reserve-keyword
              :disabled="false"
              :remote-method="(val) => remoteMethod('5', val)"
              :loading="loadingselect"
              placeholder="发货人电话"
              ref="sgSelect2"
              @input.native="sgfilterData(2)"
            >
              <el-option
                v-for="item in shipper_mobile_list"
                :key="item.value"
                :label="item.value"
                :value="item.value"
              >
              </el-option>
            </el-select> -->
          </el-form-item>
        </el-form>
      </div>
      <template #footer>
        <el-button @click="personVisible = false">取消</el-button>
        <el-button
          type="primary"
          @click="personSure"
          v-if="personbtn == 'add' && !personForm.showbtn"
          >确定</el-button
        >
        <el-button type="primary" @click="personSure" v-if="personbtn == 'edit'"
          >确定</el-button
        >
        <el-button
          type="warning"
          @click="PrintingSelf"
          v-if="personForm.showbtn == 1"
          >打印自采单</el-button
        >
        <el-button
          type="warning"
          @click="Printingtag"
          v-if="personForm.showbtn == 1"
          >打印标签</el-button
        >
      </template>
    </el-dialog>

    <!-- 打印标签 -->
    <el-dialog
      :visible.sync="setWindowDialog"
      title="选择调度室窗口"
      width="485px"
      :center="true"
      class="dialog"
    >
      <div class="contents">
        <el-form
          :model="dialogForm"
          label-position="right"
          ref="dialogForm"
          :rules="rule1"
        >
          <el-form-item label="调度室窗口" prop="window_id">
            <el-select
              v-model="dialogForm.window_id"
              placeholder="请选择调度室窗口"
              class="handle-select mr10"
            >
              <el-option
                :key="index"
                :label="item.name"
                :value="item.id"
                v-for="(item, index) in windowList"
              ></el-option>
            </el-select>
          </el-form-item>

          <el-row v-for="(item, i) in dialogForm.print_arr" :key="i">
            <div class="position">
              <el-form-item
                :label="`${item.product_name}——打印件数`"
                :prop="`print_arr.${i}.product_num`"
                :rules="rulesnum"
              >
                <el-input
                  v-model="item.product_num"
                  :disabled="false"
                  clearable
                  placeholder="请输入打印件数"
                ></el-input>
              </el-form-item>
            </div>
          </el-row>
        </el-form>
      </div>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="adminWindow">确定</el-button>
        </span>
      </template>
    </el-dialog>
  </div>
</template>
<script>
import debounce from "lodash/debounce";
import { SELF_PROCUREMENT_STATUS_ENUM } from "./utils/enum/index.js";
import { BASE } from "@/api";
export default {
  name: "CityStoreManage",
  components: {},
  data() {
    return {
      SELF_PROCUREMENT_STATUS_ENUM,
      isShowEdit: false, // 是否显示编辑按钮
      loading: false,
      loadingselect: false,
      pay_at: "",
      cityStoreList: [], // 城市仓列表
      selfTakeList: [], // 自提点列表
      zitList: [], // 自提点列表
      formData: {
        logistics_id: "", //		是	城市仓id
        delivery_id: "", //		是	自提点id
        receiver_name: "", //		是	收货人姓名
        receiver_mobile: "", //		是	收货人电话
        product_name: "", //		是	商品名称
        shipper_name: "", //		是	发货人名称
        shipper_mobile: "", //		是	发货人电话
        start_time: "", //		是	开始时间
        end_time: "", //		是	结束时间
        self_supply_no: "", //		是	SPR202405201816109846W8X7PBB自采品编号
        business_user_name: "",
        qc_status: "", //	是	状态
        status: "", //	是	状态
        source:'',
        page: 1,
        pageSize: 10,
      }, // 表单参数
      personTitle: "",
      personbtn: "",
      personVisible: false,
      personForm: {
        business_id: "", //	T文本	是	集配中心id
        logistics_id: "", //	T文本	是	城市仓id
        delivery_id: "", //	T文本	是	自提点id
        receiver_name: "", //	T文本	是	收货人名称
        receiver_mobile: "", //	T文本	是	收货人电话
        product_arr: [
          {
            product_name: "",
            product_num: "",
            each_kilogram: "",
            weight: "",
          },
        ],
        each_kilogram: "",

        shipper_name: "", //	T文本	是	发货人名称

        shipper_mobile: "", //	T文本	是	发货人电话
      },

      dialogForm: { window_id: "", print_arr: [] },
      setWindowDialog: false,
      windowList: [],
      rule1: {
        window_id: [
          { required: true, message: "请选择调度室窗口", trigger: "blur" },
        ],
      },
      rulesnum: [
        {
          required: true,
          message: "请输入件数",
          trigger: "blur",
        },
        {
          validator: (rule, value, callback) => {
            if (/^(?:[1-9]\d*)$/.test(value) == false) {
              callback(new Error("请输入正整数"));
            } else {
              callback();
            }
          },
          trigger: "blur",
        },
      ],
      fromrules: {
        business_id: [
          {
            required: true,
            message: "请输入集配中心",
            trigger: ["blur", "change"],
          },
        ],
        logistics_id: [
          {
            required: true,
            message: "请选择城市仓",
            trigger: ["blur", "change"],
          },
        ],
        delivery_id: [
          {
            required: true,
            message: "请选择自提点",
            trigger: ["blur", "change"],
          },
        ],
        receiver_name: [
          { required: true, message: "请输入收货人姓名", trigger: "blur" },
        ],
        receiver_mobile: [
          { required: true, message: "请输入收货人电话", trigger: "blur" },
          { max: 11, message: "长度11位", trigger: ["blur"] },
        ],
        // shipper_name: [
        //   { required: true, message: "请输入发货人姓名", trigger: "blur" },
        // ],
        // shipper_mobile: [
        //   { required: true, message: "请输入发货人电话", trigger: "blur" },
        //   { max: 11, message: "长度11位", trigger: ["blur"] },
        // ],
      },
      product_name_list: [],
      receiver_name_list: [],
      receiver_mobile_list: [],
      shipper_name_list: [],
      shipper_mobile_list: [],
      business_id: "",
      business_name: "",
      tableData: [], // 城市仓列表
      total: 0,
    };
  },
  mounted() {
    this.onInitData();
  },
  methods: {
    numchangeobj(e) {
      if (
        Number(this.personForm.product_num) &&
        Number(this.personForm.each_kilogram)
      ) {
        this.personForm.weight = (
          Number(this.personForm.product_num) *
          Number(this.personForm.each_kilogram)
        ).toFixed(2);
      }
    },
    numchange(e) {
      if (
        Number(this.personForm.product_arr[e].product_num) &&
        Number(this.personForm.product_arr[e].each_kilogram)
      ) {
        this.personForm.product_arr[e].weight = (
          Number(this.personForm.product_arr[e].product_num) *
          Number(this.personForm.product_arr[e].each_kilogram)
        ).toFixed(2);
      }
    },
    deliverychange(e) {
      this.zitList.forEach((el) => {
        if (el.id == this.personForm.delivery_id) {
          this.personForm.logistics_id = el.logistics.id;
          return;
        }
      });
    },
    name_blur(e) {
      console.log(e, "输入姓名选择手机号");
      this.$api.logistics
        .selfPurchasemobile({
          type: e,
          keywords:
            e == 2
              ? this.personForm.receiver_name
              : this.personForm.shipper_name,
        })
        .then((res) => {
          console.log(res);
          if (e == 2) {
            this.personForm.receiver_mobile = res.data;
          } else {
            this.personForm.shipper_mobile = res.data;
          }
        });
    },
    sgfilterData(e) {
      if (e == 1) {
        var str = this.$refs.sgSelect.$data.selectedLabel; // 此属性得到输入的文字
        // 控制的js
        if (str.length > 11) {
          this.$refs.sgSelect.$data.selectedLabel = str.substr(0, 11);
        }
      } else {
        var str = this.$refs.sgSelect2.$data.selectedLabel; // 此属性得到输入的文字
        // 控制的js
        if (str.length > 11) {
          this.$refs.sgSelect2.$data.selectedLabel = str.substr(0, 11);
        }
      }
    },
    onInitData() {
      this.getAjaxCityStoreList();
      this.hqlist();
      this.roomWindows();
      this.hqselfPurchasedeliverylist("");
    },
    roomWindows() {
      this.$api.logistics.roomWindows().then((res) => {
        if (res.code == 20000) {
          this.windowList = res.data;
        }
      });
    },
    adminWindow() {
      this.$refs.dialogForm.validate((valid) => {
        if (valid) {
          this.$api.logistics
            .productlabelprint({
              self_supply_no: this.personForm.self_supply_no,
              ...this.dialogForm,
            })
            .then((res) => {
              //   console.log(res, "打印成功还没成功");
              this.$message({
                message: "打印成功",
                type: "success",
              });
              this.setWindowDialog = false;
            });
        }
      });
    },
    addproduct() {
      this.personForm.product_arr.push({
        product_name: "",
        product_num: "",
        each_kilogram: "",
        weight: "",
      });
    },
    PrintingSelf() {
      // /api

      window.open(
        `${BASE.PRO2 + "/selfPurchase/pdf/print"}` +
          `?self_supply_no=${this.personForm.self_supply_no}&token=${
            sessionStorage.getItem("token")
              ? sessionStorage.getItem("token")
              : ""
          }`
      );
    },
    Printingtag() {
      this.setWindowDialog = true;
    },
    delproduct(i) {
      this.personForm.product_arr.splice(i, 1);
    },
    remoteMethod(type, query, i) {
      // console.log( query,111111111111)
      this.queryname(type, query, i);
      if (query !== "") {
        this.loadingselect = true;
        this.$api.logistics
          .selfPurchaselistsearch({
            keywords: query, //	是	pi模糊查询
            type: type,
          })
          .then((res) => {
            this.typemethod(type, query, res, i);
            this.loadingselect = false;
          });
      } else {
        this.typemethod(query, [], i);
      }
    },
    querySearchAsync(type, queryString, cb, i) {
      // console.log( query,111111111111)
      // this.queryname(type, queryString,cb, i);
      if (queryString !== "") {
        this.loadingselect = true;
        this.$api.logistics
          .selfPurchaselistsearch({
            keywords: queryString, //	是	pi模糊查询
            type: type,
          })
          .then((res) => {
            // this.typemethod(type, queryString, res, i);
            cb(res.data.data ? res.data.data : []);
            this.loadingselect = false;
          });
      } else {
        this.typemethod(queryString, [], i);
      }
    },
    typemethod(type, query, res, i) {
      if (type == 2) {
        this.receiver_name_list = res.data.data ? res.data.data : [];
      } else if (type == 3) {
        this.receiver_mobile_list = res.data.data ? res.data.data : [];
      } else if (type == 4) {
        this.shipper_name_list = res.data.data ? res.data.data : [];
      } else if (type == 5) {
        this.shipper_mobile_list = res.data.data ? res.data.data : [];
      } else if (type == 1) {
        this.product_name_list = res.data.data ? res.data.data : [];
      }
    },
    queryname(type, query, i) {
      if (type == 2) {
        this.personForm.receiver_name = query;
      } else if (type == 3) {
        this.personForm.receiver_mobile = query;
      } else if (type == 4) {
        this.personForm.shipper_name = query;
      } else if (type == 5) {
        this.personForm.shipper_mobile = query;
      } else if (type == 1) {
        this.personForm.product_arr[i].product_name = query;
      }
    },
    //添加按钮
    adddialog() {
      this.personTitle = "新增自采登记";
      Object.assign(this.$data.personForm, this.$options.data().personForm);
      this.hqselfPurchasedeliverylist("");
      this.personForm.showbtn = "";
      this.personbtn = "add";
      this.personForm = JSON.parse(JSON.stringify(this.personForm));
      console.log(this.personForm);
      this.personForm.business_id = this.business_id;
      this.personForm.business_name = this.business_name;
      this.personVisible = true;
    },
    //编辑接口
    edit(row) {
      this.personTitle = "编辑自采登记";
      this.personbtn = "edit";
      //   for (let key in this.personForm) {
      //     this.personForm[key] = row[key];
      //   }
      this.$api.logistics.selfPurchasedetail({ id: row.id }).then((res) => {
        console.log(res);
        this.personForm = res.data;
        this.personForm = {
          ...res.data,
          each_kilogram: isNaN(
            (
              Number(this.personForm.weight) /
              Number(this.personForm.product_num)
            ).toFixed(2)
          )
            ? 0
            : (
                Number(this.personForm.weight) /
                Number(this.personForm.product_num)
              ).toFixed(2),
        };
        this.personForm.showbtn = 1;
        let obj = {
          product_name: res.data.product_name,
          product_num: res.data.product_num,
          id: res.data.id,
        };
        this.dialogForm.print_arr = [];
        this.dialogForm.print_arr.push(obj);
        this.hqselfPurchasedeliverylist(this.personForm.logistics_id);
        this.personVisible = true;
      });
    },
    //编辑点击保存
    personSure() {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          if (this.personbtn == "add") {
            this.$api.logistics.selfPurchaseadd(this.personForm).then((res) => {
              this.$message({
                message: "添加成功",
                type: "success",
              });
              this.personForm.self_supply_no = res.data.self_supply_no;
              this.dialogForm.print_arr = res.data.product;
              //   this.personbtn = "";
              this.personForm.showbtn = 1;
              this.hqlist();
            });
          } else {
            this.$api.logistics
              .selfPurchaseedit(this.personForm)
              .then((res) => {
                this.$message({
                  message: "编辑成功",
                  type: "success",
                });
                this.personVisible = false;
                this.personbtn = "";
                this.hqlist();
              });
          }
        }
      });
    },
    //弹框选择城市仓联动自提点
    changelogistics(e) {
      this.zitList = []; // 重置自提点
      this.personForm.delivery_id = ""; // 重置自提点搜索

      this.hqselfPurchasedeliverylist(e);
    },
    hqselfPurchasedeliverylist(e) {
      this.$api.logistics
        .seldeliverylist({ logistics_id: e, page: 1, pageSize: 999999 })
        .then((res) => {
          this.zitList = res.data.data;
        });
    },
    hqtime() {
      if (this.pay_at != null) {
        if (this.pay_at.length > 0) {
          this.formData.start_time = this.pay_at[0];
          this.formData.end_time = this.pay_at[1];
        } else {
          this.formData.start_time = "";
          this.formData.end_time = "";
        }
      } else {
        this.formData.start_time = "";
        this.formData.end_time = "";
      }
    },
    hqlist() {
      this.hqtime();
      this.loading = true;
      this.$api.logistics.selfPurchaselist(this.formData).then((res) => {
        console.log(res, "自采品列表数据");
        this.isShowEdit = res.data.is_edit;
        this.loading = false;
        this.tableData = res.data.data;
        this.total = res.data.total;
        this.business_id = res.data.business.id;
        this.business_name = res.data.business.name;
      });
    },

    exportHandle() {
      this.hqtime();
      let url = `${
        BASE.PRO2
      }/selfPurchase/list/export?token=${sessionStorage.getItem("token")}`;
      for (let key in this.formData) {
        if (key != "page" && key != "pageSize") {
          url = url + `&${key}=${this.formData[key]}`;
        }
      }
      window.open(url, "_blank");
    },
    /**
     * 重置表单，并刷新
     */
    onHandleRefresh() {
      Object.assign(this.$data.formData, this.$options.data().formData);
      this.pay_at = [];
      this.hqlist();
    },
    /**
     * 搜索表单
     */
    onSearch() {
      this.formData.page = 1;
      this.hqlist();
    },

    /**
     * 每页条数发生改变
     */
    onHandleSizeChange(val) {
      this.formData.pageSize = val;
      this.hqlist();
    },
    /**
     * 页码发生改变
     */
    onHandleCurrentChange(val) {
      this.formData.page = val;
      this.hqlist();
    },

    /*
     * 城市仓发生变化
     */
    onChangeCityStore(data) {
      this.selfTakeList = []; // 重置自提点
      this.formData.delivery_id = ""; // 重置自提点搜索
      const params = {
        name: "",
        logisticsId: data,
      };
      this.getAjaxSelfTakeList(params);
    },
    /**
     * 搜索数据项
     */
    onFilterMethod(type, data) {
      if (type === "cityStore") {
        this.getAjaxCityStoreList(data);
      }
      if (type === "selfTake") {
        const params = {
          name: data,
          logisticsId: this.formData.logistics_id,
        };
        this.getAjaxSelfTakeList(params);
      }
    },
    /**
     * 失焦时，初始化下拉框
     */
    onChangeVisible(type, data) {
      if (!data && type === "cityStore") {
        this.getAjaxCityStoreList();
      }
      if (!data && type === "selfTake") {
        const params = {
          name: "",
          logisticsId: this.formData.logistics_id,
        };
        this.getAjaxSelfTakeList(params);
      }
    },
    /**
     * 获取城市仓列表
     */
    getAjaxCityStoreList: debounce(async function (data) {
      const params = {
        name: data,
        page: 1,
        pageSize: 1000,
      };
      try {
        const { data } = await this.$api.logistics.selfPurchaseLogisticsList(
          params
        );
        this.cityStoreList = data.data;
        console.log("ajx getAjaxCityStoreList", data.data);
      } catch (err) {
        console.log("ajx getAjaxCityStoreList err", err);
      }
    }, 500),
    /**
     * 获取自提点列表
     */
    getAjaxSelfTakeList: debounce(async function ({
      name = "",
      logisticsId = "",
    }) {
      /**
       * 城市仓为必传参数
       * 防止接口在删除城市仓前请求数据，故先判断条件
       */
      if (!logisticsId || !this.formData.logistics_id) {
        return;
      }
      const params = {
        name,
        logistics_id: (logisticsId && [logisticsId]) || [],
        page: 1,
        pageSize: 1000,
      };
      try {
        const { data } = await this.$api.logistics.seldeliverylist(params);
        this.selfTakeList = data.data;
        console.log("ajx getAjaxSelfTakeList", data.data);
      } catch (err) {
        console.log("ajx getAjaxSelfTakeList err", err);
      }
    },
    500),
  },
};
</script>
<style lang="scss">
.position {
  position: relative;
}
.from-wraps {
  /** 修改element基础样式 */
  .el-select .el-input.is-disabled .el-input__inner {
    color: #333333;
  }

  .el-input.is-disabled .el-input__inner {
    color: #333333;
  }
}
.position-subset {
  position: absolute;
  right: -20%;
  top: 38%;
  .el-icon-circle-plus {
    font-size: 45px;
  }
  .el-icon-delete-solid {
    font-size: 45px;
  }
}
.self-procurement-register {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  height: 100%;
  box-sizing: border-box;
  padding: 10px;
  .showImg {
    text-align: center;
    height: 76vh;
  }
  .form-wrap {
    background: #ffffff;
    padding: 13px 15px;
    box-sizing: border-box;
    display: flex;
    .seach {
      flex: 1;
      display: flex;
      //   justify-content: flex-end;
      display: flex;
      flex-wrap: wrap;
      gap: 10px;
      .inputs {
        // width: 207px;
        // margin-right: 8px;
      }
    }
    .refresh {
      background: #333333;
      border-color: #333333;
    }
  }
  .table-wrap {
    flex: 1;
    overflow-y: hidden;
    .text-btn {
      color: $theme-color;
      cursor: pointer;
    }
    .el-button {
      &--text {
        padding: 10px 0;
      }
    }
  }
  .fy {
    box-sizing: border-box;
    padding: 10px 20px;
  }
  .contents {
    padding-left: 55px;
    padding-right: 55px;
    padding-top: 23px;
  }
  .base-margin {
    margin: 0 10px;
    white-space: nowrap;
  }
  .hide {
    .el-upload--picture-card {
      display: none;
    }
  }
}
</style>
