var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "self-procurement-register" },
    [
      _c("div", { staticClass: "form-wrap" }, [
        _c("div", { staticClass: "seach" }, [
          _c(
            "div",
            { staticClass: "inputs" },
            [
              _c("el-input", {
                attrs: { placeholder: "登记商品名称", clearable: "" },
                model: {
                  value: _vm.formData.product_name,
                  callback: function ($$v) {
                    _vm.$set(_vm.formData, "product_name", $$v)
                  },
                  expression: "formData.product_name",
                },
              }),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "inputs" },
            [
              _c("el-input", {
                attrs: { placeholder: "登记员姓名", clearable: "" },
                model: {
                  value: _vm.formData.business_user_name,
                  callback: function ($$v) {
                    _vm.$set(_vm.formData, "business_user_name", $$v)
                  },
                  expression: "formData.business_user_name",
                },
              }),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "inputs" },
            [
              _c("el-input", {
                attrs: { placeholder: "收货人姓名", clearable: "" },
                model: {
                  value: _vm.formData.receiver_name,
                  callback: function ($$v) {
                    _vm.$set(_vm.formData, "receiver_name", $$v)
                  },
                  expression: "formData.receiver_name",
                },
              }),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "inputs" },
            [
              _c("el-input", {
                attrs: { placeholder: "收货人电话", clearable: "" },
                model: {
                  value: _vm.formData.receiver_mobile,
                  callback: function ($$v) {
                    _vm.$set(_vm.formData, "receiver_mobile", $$v)
                  },
                  expression: "formData.receiver_mobile",
                },
              }),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "inputs" },
            [
              _c(
                "el-select",
                {
                  attrs: {
                    filterable: "",
                    clearable: "",
                    placeholder: "选择城市仓",
                  },
                  on: { change: _vm.onChangeCityStore },
                  model: {
                    value: _vm.formData.logistics_id,
                    callback: function ($$v) {
                      _vm.$set(_vm.formData, "logistics_id", $$v)
                    },
                    expression: "formData.logistics_id",
                  },
                },
                _vm._l(_vm.cityStoreList, function (item) {
                  return _c("el-option", {
                    key: item.id,
                    attrs: { label: item.name, value: item.id },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "inputs" },
            [
              _c(
                "el-select",
                {
                  attrs: {
                    filterable: "",
                    clearable: "",
                    "filter-method": (val) =>
                      _vm.onFilterMethod("selfTake", val),
                    placeholder: "选择自提点",
                  },
                  on: {
                    "visible-change": (val) =>
                      _vm.onChangeVisible("selfTake", val),
                  },
                  model: {
                    value: _vm.formData.delivery_id,
                    callback: function ($$v) {
                      _vm.$set(_vm.formData, "delivery_id", $$v)
                    },
                    expression: "formData.delivery_id",
                  },
                },
                _vm._l(_vm.selfTakeList, function (item) {
                  return _c("el-option", {
                    key: item.id,
                    attrs: { label: item.name, value: item.id },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "inputs" },
            [
              _c("el-input", {
                attrs: { placeholder: "发货人姓名", clearable: "" },
                model: {
                  value: _vm.formData.shipper_name,
                  callback: function ($$v) {
                    _vm.$set(_vm.formData, "shipper_name", $$v)
                  },
                  expression: "formData.shipper_name",
                },
              }),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "inputs" },
            [
              _c("el-input", {
                attrs: { placeholder: "发货人电话", clearable: "" },
                model: {
                  value: _vm.formData.shipper_mobile,
                  callback: function ($$v) {
                    _vm.$set(_vm.formData, "shipper_mobile", $$v)
                  },
                  expression: "formData.shipper_mobile",
                },
              }),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "inputs" },
            [
              _c("el-input", {
                attrs: { placeholder: "自采品编码", clearable: "" },
                model: {
                  value: _vm.formData.self_supply_no,
                  callback: function ($$v) {
                    _vm.$set(_vm.formData, "self_supply_no", $$v)
                  },
                  expression: "formData.self_supply_no",
                },
              }),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "inputs" },
            [
              _c(
                "el-select",
                {
                  attrs: { clearable: "", placeholder: "状态" },
                  model: {
                    value: _vm.formData.qc_status,
                    callback: function ($$v) {
                      _vm.$set(_vm.formData, "qc_status", $$v)
                    },
                    expression: "formData.qc_status",
                  },
                },
                [
                  _c("el-option", { attrs: { label: "全部", value: "0" } }),
                  _c("el-option", { attrs: { label: "未复核", value: "10" } }),
                  _c("el-option", { attrs: { label: "已复核", value: "20" } }),
                  _c("el-option", { attrs: { label: "已扣款", value: "30" } }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "inputs" },
            [
              _c("el-date-picker", {
                attrs: {
                  "time-arrow-control": true,
                  type: "daterange",
                  format: "yyyy-MM-dd",
                  "value-format": "yyyy-MM-dd",
                  "range-separator": "—",
                  "start-placeholder": "登记时间始",
                  "end-placeholder": "登记时间止",
                },
                model: {
                  value: _vm.pay_at,
                  callback: function ($$v) {
                    _vm.pay_at = $$v
                  },
                  expression: "pay_at",
                },
              }),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "inputs" },
            [
              _c(
                "el-select",
                {
                  attrs: { clearable: "", placeholder: "请选择来源" },
                  model: {
                    value: _vm.formData.source,
                    callback: function ($$v) {
                      _vm.$set(_vm.formData, "source", $$v)
                    },
                    expression: "formData.source",
                  },
                },
                [
                  _c("el-option", { attrs: { label: "线上", value: "2" } }),
                  _c("el-option", { attrs: { label: "线下", value: "1" } }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "inputs" },
            [
              _c(
                "el-select",
                {
                  attrs: { clearable: "", placeholder: "请选择登记状态" },
                  model: {
                    value: _vm.formData.status,
                    callback: function ($$v) {
                      _vm.$set(_vm.formData, "status", $$v)
                    },
                    expression: "formData.status",
                  },
                },
                [
                  _c("el-option", { attrs: { label: "已失效", value: "1" } }),
                  _c("el-option", { attrs: { label: "正常", value: "0" } }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "inputs" },
            [
              _c("el-button", {
                staticClass: "refresh",
                attrs: { type: "primary", icon: "el-icon-refresh" },
                on: { click: _vm.onHandleRefresh },
              }),
              _c(
                "el-button",
                {
                  attrs: { type: "primary", icon: "el-icon-search" },
                  on: { click: _vm.onSearch },
                },
                [_vm._v("查询")]
              ),
              _c(
                "el-button",
                {
                  attrs: {
                    type: "warning",
                    icon: "el-icon-circle-plus-outline",
                  },
                  on: { click: _vm.adddialog },
                },
                [_vm._v("添加")]
              ),
              _c(
                "el-button",
                {
                  attrs: { type: "warning", icon: "el-icon-download" },
                  on: { click: _vm.exportHandle },
                },
                [_vm._v("导 出")]
              ),
            ],
            1
          ),
        ]),
      ]),
      _c(
        "div",
        { staticClass: "table-wrap" },
        [
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.loading,
                  expression: "loading",
                },
              ],
              attrs: {
                data: _vm.tableData,
                height: "100%",
                border: true,
                "header-cell-style": {
                  color: "#333333",
                  background: "#EFF6FF",
                },
              },
            },
            [
              _c("el-table-column", {
                attrs: {
                  type: "index",
                  width: "100px",
                  align: "center",
                  label: "序号",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "business_name",
                  align: "center",
                  label: "集配中心",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "self_supply_no",
                  align: "center",
                  label: "自采品编码",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "logistics_name",
                  align: "center",
                  label: "城市仓",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "delivery_name",
                  align: "center",
                  label: "自提点",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "business_user_name",
                  align: "center",
                  label: "登记员姓名",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "receiver_name",
                  align: "center",
                  label: "收货人姓名",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "receiver_mobile",
                  align: "center",
                  label: "收货人电话",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "shipper_name",
                  align: "center",
                  label: "发货人姓名",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "shipper_mobile",
                  align: "center",
                  label: "发货人电话",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "product_name",
                  align: "center",
                  label: "登记商品名称",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "create_time",
                  align: "center",
                  label: "登记时间",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "weight",
                  align: "center",
                  label: "登记重量（斤）",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "qc_weight",
                  align: "center",
                  label: "复核确认重量（斤）",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "product_num",
                  align: "center",
                  label: "登记件数",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "qc_product_num",
                  align: "center",
                  label: "复核确认件数",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "freight_amount",
                  align: "center",
                  label: "服务费金额",
                },
              }),
              _c("el-table-column", {
                attrs: { align: "center", label: "打单人员" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _vm._v(
                          " " +
                            _vm._s(
                              scope.row.print_business_user
                                ? scope.row.print_business_user.nickname
                                : ""
                            ) +
                            " "
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { prop: "qc_status", align: "center", label: "状态" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        scope.row.qc_status == 10
                          ? _c("span", [_vm._v(" 未复核")])
                          : scope.row.qc_status == 20
                          ? _c("span", [_vm._v("已复核")])
                          : scope.row.qc_status == 30
                          ? _c("span", [_vm._v("已扣款")])
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "qc_status",
                  align: "center",
                  label: "登记状态",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        scope.row.status == 0
                          ? _c("span", [_vm._v(" 正常")])
                          : scope.row.status == 1
                          ? _c("span", [_vm._v("已失效")])
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { label: "操作", align: "center" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        scope.row.status == 0
                          ? _c("div", [
                              scope.row.source == 2
                                ? _c(
                                    "div",
                                    [
                                      scope.row.qc_status != 30
                                        ? _c(
                                            "el-button",
                                            {
                                              attrs: { type: "text" },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.edit(scope.row)
                                                },
                                              },
                                            },
                                            [_vm._v("编辑")]
                                          )
                                        : _vm._e(),
                                    ],
                                    1
                                  )
                                : _c(
                                    "div",
                                    [
                                      _vm.isShowEdit ||
                                      Number(scope.row.qc_status) <
                                        _vm.SELF_PROCUREMENT_STATUS_ENUM["20"]
                                          .value
                                        ? _c(
                                            "el-button",
                                            {
                                              attrs: { type: "text" },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.edit(scope.row)
                                                },
                                              },
                                            },
                                            [_vm._v("编辑")]
                                          )
                                        : _vm._e(),
                                    ],
                                    1
                                  ),
                            ])
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "fy" },
        [
          _c("el-pagination", {
            attrs: {
              type: "primary",
              background: "",
              "current-page": _vm.formData.page,
              "page-sizes": [10, 20, 50, 100],
              "page-size": _vm.formData.pageSize,
              layout: "total, sizes, prev, pager, next, jumper",
              total: _vm.total,
            },
            on: {
              "size-change": _vm.onHandleSizeChange,
              "current-change": _vm.onHandleCurrentChange,
            },
          }),
        ],
        1
      ),
      _vm.personVisible
        ? _c(
            "el-dialog",
            {
              attrs: {
                center: true,
                visible: _vm.personVisible,
                title: _vm.personTitle,
                "append-to-body": true,
              },
              on: {
                close: function ($event) {
                  _vm.personVisible = false
                },
                "update:visible": function ($event) {
                  _vm.personVisible = $event
                },
              },
              scopedSlots: _vm._u(
                [
                  {
                    key: "footer",
                    fn: function () {
                      return [
                        _c(
                          "el-button",
                          {
                            on: {
                              click: function ($event) {
                                _vm.personVisible = false
                              },
                            },
                          },
                          [_vm._v("取消")]
                        ),
                        _vm.personbtn == "add" && !_vm.personForm.showbtn
                          ? _c(
                              "el-button",
                              {
                                attrs: { type: "primary" },
                                on: { click: _vm.personSure },
                              },
                              [_vm._v("确定")]
                            )
                          : _vm._e(),
                        _vm.personbtn == "edit"
                          ? _c(
                              "el-button",
                              {
                                attrs: { type: "primary" },
                                on: { click: _vm.personSure },
                              },
                              [_vm._v("确定")]
                            )
                          : _vm._e(),
                        _vm.personForm.showbtn == 1
                          ? _c(
                              "el-button",
                              {
                                attrs: { type: "warning" },
                                on: { click: _vm.PrintingSelf },
                              },
                              [_vm._v("打印自采单")]
                            )
                          : _vm._e(),
                        _vm.personForm.showbtn == 1
                          ? _c(
                              "el-button",
                              {
                                attrs: { type: "warning" },
                                on: { click: _vm.Printingtag },
                              },
                              [_vm._v("打印标签")]
                            )
                          : _vm._e(),
                      ]
                    },
                    proxy: true,
                  },
                ],
                null,
                false,
                3204231955
              ),
            },
            [
              _c(
                "div",
                { staticStyle: { width: "80%" } },
                [
                  _c(
                    "el-form",
                    {
                      ref: "ruleForm",
                      staticClass: "demo-ruleForm from-wraps",
                      attrs: {
                        model: _vm.personForm,
                        rules: _vm.fromrules,
                        "label-width": "20%",
                      },
                    },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "集配中心", prop: "business_id" } },
                        [
                          _c("el-input", {
                            attrs: {
                              disabled: true,
                              placeholder: "请输入集配中心",
                            },
                            model: {
                              value: _vm.personForm.business_name,
                              callback: function ($$v) {
                                _vm.$set(_vm.personForm, "business_name", $$v)
                              },
                              expression: "personForm.business_name",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        {
                          attrs: { label: "选择城市仓", prop: "logistics_id" },
                        },
                        [
                          _c(
                            "el-select",
                            {
                              attrs: {
                                filterable: "",
                                clearable: "",
                                placeholder: "选择城市仓",
                              },
                              on: { change: _vm.changelogistics },
                              model: {
                                value: _vm.personForm.logistics_id,
                                callback: function ($$v) {
                                  _vm.$set(_vm.personForm, "logistics_id", $$v)
                                },
                                expression: "personForm.logistics_id",
                              },
                            },
                            _vm._l(_vm.cityStoreList, function (item) {
                              return _c("el-option", {
                                key: item.id,
                                attrs: { label: item.name, value: item.id },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        { attrs: { label: "选择自提点", prop: "delivery_id" } },
                        [
                          _c(
                            "el-select",
                            {
                              attrs: {
                                filterable: "",
                                clearable: "",
                                placeholder: "选择自提点",
                              },
                              on: { change: _vm.deliverychange },
                              model: {
                                value: _vm.personForm.delivery_id,
                                callback: function ($$v) {
                                  _vm.$set(_vm.personForm, "delivery_id", $$v)
                                },
                                expression: "personForm.delivery_id",
                              },
                            },
                            _vm._l(_vm.zitList, function (item) {
                              return _c("el-option", {
                                key: item.id,
                                attrs: { label: item.name, value: item.id },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                      _vm.personbtn == "add"
                        ? _c(
                            "div",
                            _vm._l(
                              _vm.personForm.product_arr,
                              function (item, i) {
                                return _c("el-row", { key: i }, [
                                  _c(
                                    "div",
                                    { staticClass: "position" },
                                    [
                                      _c(
                                        "el-form-item",
                                        {
                                          attrs: {
                                            label: "商品名称",
                                            prop: `product_arr.${i}.product_name`,
                                            rules: [
                                              {
                                                required: true,
                                                message: "请输入商品名称",
                                                trigger: "blur",
                                              },
                                            ],
                                          },
                                        },
                                        [
                                          _c("el-autocomplete", {
                                            staticStyle: { width: "100%" },
                                            attrs: {
                                              "fetch-suggestions": (
                                                queryString,
                                                cb
                                              ) =>
                                                _vm.querySearchAsync(
                                                  "1",
                                                  queryString,
                                                  cb
                                                ),
                                              placeholder: "商品名称",
                                            },
                                            model: {
                                              value: item.product_name,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  item,
                                                  "product_name",
                                                  $$v
                                                )
                                              },
                                              expression: "item.product_name",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "el-form-item",
                                        {
                                          attrs: {
                                            label: "件数",
                                            prop: `product_arr.${i}.product_num`,
                                            rules: _vm.rulesnum,
                                          },
                                        },
                                        [
                                          _c("el-input", {
                                            attrs: {
                                              disabled: false,
                                              clearable: "",
                                              placeholder: "请输入件数",
                                            },
                                            on: {
                                              blur: function ($event) {
                                                return _vm.numchange(i)
                                              },
                                            },
                                            model: {
                                              value: item.product_num,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  item,
                                                  "product_num",
                                                  $$v
                                                )
                                              },
                                              expression: "item.product_num",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "el-form-item",
                                        {
                                          attrs: {
                                            label: "每件斤数",
                                            prop: `product_arr.${i}.each_kilogram`,
                                          },
                                        },
                                        [
                                          _c("el-input", {
                                            attrs: {
                                              disabled: false,
                                              clearable: "",
                                              placeholder: "请输入每件斤数",
                                            },
                                            on: {
                                              blur: function ($event) {
                                                return _vm.numchange(i)
                                              },
                                            },
                                            model: {
                                              value: item.each_kilogram,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  item,
                                                  "each_kilogram",
                                                  $$v
                                                )
                                              },
                                              expression: "item.each_kilogram",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "el-form-item",
                                        {
                                          attrs: {
                                            label: "总斤数",
                                            prop: `product_arr.${i}.weight`,
                                            rules: [
                                              {
                                                required: true,
                                                message: "请输入总斤数",
                                                trigger: "blur",
                                              },
                                            ],
                                          },
                                        },
                                        [
                                          _c("el-input", {
                                            attrs: {
                                              disabled: false,
                                              clearable: "",
                                              placeholder: "请输入总斤数",
                                            },
                                            model: {
                                              value: item.weight,
                                              callback: function ($$v) {
                                                _vm.$set(item, "weight", $$v)
                                              },
                                              expression: "item.weight",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "div",
                                        { staticClass: "position-subset" },
                                        [
                                          _vm.personForm.product_arr.length ==
                                            i + 1 && !_vm.personForm.showbtn
                                            ? _c("i", {
                                                staticClass:
                                                  "el-icon-circle-plus",
                                                on: { click: _vm.addproduct },
                                              })
                                            : _vm._e(),
                                          _vm.personForm.product_arr.length !=
                                            1 && !_vm.personForm.showbtn
                                            ? _c("i", {
                                                staticClass:
                                                  "el-icon-delete-solid",
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.delproduct(i)
                                                  },
                                                },
                                              })
                                            : _vm._e(),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                ])
                              }
                            ),
                            1
                          )
                        : _c(
                            "div",
                            [
                              _c("el-row", [
                                _c(
                                  "div",
                                  { staticClass: "position" },
                                  [
                                    _c(
                                      "el-form-item",
                                      {
                                        attrs: {
                                          label: "商品名称",
                                          prop: "product_name",
                                          rules: [
                                            {
                                              required: true,
                                              message: "请输入商品名称",
                                              trigger: "blur",
                                            },
                                          ],
                                        },
                                      },
                                      [
                                        _c("el-autocomplete", {
                                          staticStyle: { width: "100%" },
                                          attrs: {
                                            "fetch-suggestions": (
                                              queryString,
                                              cb
                                            ) =>
                                              _vm.querySearchAsync(
                                                "1",
                                                queryString,
                                                cb
                                              ),
                                            placeholder: "商品名称",
                                          },
                                          model: {
                                            value: _vm.personForm.product_name,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.personForm,
                                                "product_name",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "personForm.product_name",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "el-form-item",
                                      {
                                        attrs: {
                                          label: "件数",
                                          prop: "product_num",
                                          rules: [
                                            {
                                              required: true,
                                              message: "请输入件数",
                                              trigger: "blur",
                                            },
                                          ],
                                        },
                                      },
                                      [
                                        _c("el-input", {
                                          attrs: {
                                            disabled: false,
                                            clearable: "",
                                            placeholder: "请输入件数",
                                          },
                                          on: {
                                            blur: function ($event) {
                                              return _vm.numchangeobj()
                                            },
                                          },
                                          model: {
                                            value: _vm.personForm.product_num,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.personForm,
                                                "product_num",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "personForm.product_num",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "el-form-item",
                                      {
                                        attrs: {
                                          label: "每件斤数",
                                          prop: "each_kilogram",
                                        },
                                      },
                                      [
                                        _c("el-input", {
                                          attrs: {
                                            disabled: false,
                                            clearable: "",
                                            placeholder: "请输入每件斤数",
                                          },
                                          on: {
                                            blur: function ($event) {
                                              return _vm.numchangeobj()
                                            },
                                          },
                                          model: {
                                            value: _vm.personForm.each_kilogram,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.personForm,
                                                "each_kilogram",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "personForm.each_kilogram",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "el-form-item",
                                      {
                                        attrs: {
                                          label: "总斤数",
                                          prop: "weight",
                                          rules: [
                                            {
                                              required: true,
                                              message: "请输入总斤数",
                                              trigger: "blur",
                                            },
                                          ],
                                        },
                                      },
                                      [
                                        _c("el-input", {
                                          attrs: {
                                            disabled: false,
                                            clearable: "",
                                            placeholder: "请输入总斤数",
                                          },
                                          model: {
                                            value: _vm.personForm.weight,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.personForm,
                                                "weight",
                                                $$v
                                              )
                                            },
                                            expression: "personForm.weight",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ]),
                            ],
                            1
                          ),
                      _c(
                        "el-form-item",
                        {
                          attrs: { label: "收货人姓名", prop: "receiver_name" },
                        },
                        [
                          _c("el-autocomplete", {
                            staticStyle: { width: "100%" },
                            attrs: {
                              "fetch-suggestions": (queryString, cb) =>
                                _vm.querySearchAsync("2", queryString, cb),
                              placeholder: "收货人姓名",
                            },
                            on: {
                              blur: function ($event) {
                                return _vm.name_blur("2")
                              },
                              select: function ($event) {
                                return _vm.name_blur("2")
                              },
                            },
                            model: {
                              value: _vm.personForm.receiver_name,
                              callback: function ($$v) {
                                _vm.$set(_vm.personForm, "receiver_name", $$v)
                              },
                              expression: "personForm.receiver_name",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "收货人电话",
                            prop: "receiver_mobile",
                          },
                        },
                        [
                          _c("el-autocomplete", {
                            staticStyle: { width: "100%" },
                            attrs: {
                              "fetch-suggestions": (queryString, cb) =>
                                _vm.querySearchAsync("3", queryString, cb),
                              placeholder: "收货人电话",
                            },
                            model: {
                              value: _vm.personForm.receiver_mobile,
                              callback: function ($$v) {
                                _vm.$set(_vm.personForm, "receiver_mobile", $$v)
                              },
                              expression: "personForm.receiver_mobile",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        {
                          attrs: { label: "发货人姓名", prop: "shipper_name" },
                        },
                        [
                          _c("el-autocomplete", {
                            staticStyle: { width: "100%" },
                            attrs: {
                              "fetch-suggestions": (queryString, cb) =>
                                _vm.querySearchAsync("4", queryString, cb),
                              placeholder: "发货人姓名",
                            },
                            on: {
                              blur: function ($event) {
                                return _vm.name_blur("4")
                              },
                              select: function ($event) {
                                return _vm.name_blur("4")
                              },
                            },
                            model: {
                              value: _vm.personForm.shipper_name,
                              callback: function ($$v) {
                                _vm.$set(_vm.personForm, "shipper_name", $$v)
                              },
                              expression: "personForm.shipper_name",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "发货人电话",
                            prop: "shipper_mobile",
                          },
                        },
                        [
                          _c("el-autocomplete", {
                            staticStyle: { width: "100%" },
                            attrs: {
                              "fetch-suggestions": (queryString, cb) =>
                                _vm.querySearchAsync("5", queryString, cb),
                              placeholder: "发货人电话",
                            },
                            model: {
                              value: _vm.personForm.shipper_mobile,
                              callback: function ($$v) {
                                _vm.$set(_vm.personForm, "shipper_mobile", $$v)
                              },
                              expression: "personForm.shipper_mobile",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]
          )
        : _vm._e(),
      _c(
        "el-dialog",
        {
          staticClass: "dialog",
          attrs: {
            visible: _vm.setWindowDialog,
            title: "选择调度室窗口",
            width: "485px",
            center: true,
          },
          on: {
            "update:visible": function ($event) {
              _vm.setWindowDialog = $event
            },
          },
          scopedSlots: _vm._u([
            {
              key: "footer",
              fn: function () {
                return [
                  _c(
                    "span",
                    { staticClass: "dialog-footer" },
                    [
                      _c("el-button", { on: { click: _vm.adminWindow } }, [
                        _vm._v("确定"),
                      ]),
                    ],
                    1
                  ),
                ]
              },
              proxy: true,
            },
          ]),
        },
        [
          _c(
            "div",
            { staticClass: "contents" },
            [
              _c(
                "el-form",
                {
                  ref: "dialogForm",
                  attrs: {
                    model: _vm.dialogForm,
                    "label-position": "right",
                    rules: _vm.rule1,
                  },
                },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "调度室窗口", prop: "window_id" } },
                    [
                      _c(
                        "el-select",
                        {
                          staticClass: "handle-select mr10",
                          attrs: { placeholder: "请选择调度室窗口" },
                          model: {
                            value: _vm.dialogForm.window_id,
                            callback: function ($$v) {
                              _vm.$set(_vm.dialogForm, "window_id", $$v)
                            },
                            expression: "dialogForm.window_id",
                          },
                        },
                        _vm._l(_vm.windowList, function (item, index) {
                          return _c("el-option", {
                            key: index,
                            attrs: { label: item.name, value: item.id },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._l(_vm.dialogForm.print_arr, function (item, i) {
                    return _c("el-row", { key: i }, [
                      _c(
                        "div",
                        { staticClass: "position" },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: `${item.product_name}——打印件数`,
                                prop: `print_arr.${i}.product_num`,
                                rules: _vm.rulesnum,
                              },
                            },
                            [
                              _c("el-input", {
                                attrs: {
                                  disabled: false,
                                  clearable: "",
                                  placeholder: "请输入打印件数",
                                },
                                model: {
                                  value: item.product_num,
                                  callback: function ($$v) {
                                    _vm.$set(item, "product_num", $$v)
                                  },
                                  expression: "item.product_num",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ])
                  }),
                ],
                2
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }